<script lang="ts" setup>
import type { StepProps } from 'naive-ui'
import type { MergeAccount } from '~/types'

import ArrowLeftIcon from '~/assets/icons/direction/left.svg'

const props = defineProps<{ payload: MergeAccount }>()
defineEmits<{ (e: 'submit', payload: MergeAccount): void, (e: 'close'): void }>()

const { cloned } = useCloned(props.payload)

const { accountQuery } = useQuery()
const { data: accountToMerge, isLoading: isAccountToMergeLoading } = accountQuery.findOne(computed(() => ({ id: cloned.value.idToMerge })))
const { data: accountToMergeInto, isLoading: isAccountToMergeIntoLoading } = accountQuery.findOne(computed(() => {
  if (!cloned.value.idToMergeInto) {
    return {}
  }
  return { id: cloned.value.idToMergeInto }
}))

type MergeSteps = 'selectOne' | 'selectTwo' | 'confirm'
const stepsStatus = computed((): Record<MergeSteps, StepProps['status']> => ({
  selectOne: accountToMerge.value ? 'finish' : 'process',
  selectTwo: accountToMergeInto.value ? 'finish' : accountToMerge.value ? 'process' : 'wait',
  confirm: (accountToMerge.value && accountToMergeInto.value) ? 'process' : 'wait',
}))
const currentStep = computed((): MergeSteps => {
  if (stepsStatus.value.confirm === 'process') {
    return 'confirm'
  } else if (stepsStatus.value.selectTwo === 'process') {
    return 'selectTwo'
  }
  return 'selectOne'
})
</script>

<template>
  <div class="space-y-4">
    <TheDataCard :is-loading="isAccountToMergeLoading || isAccountToMergeIntoLoading">
      <n-steps>
        <n-step
          :title="accountToMerge ? usePersonName(accountToMerge) : $t('account.merge.steps.selectOne.title')"
          :status="stepsStatus.selectOne"
          :description="$t('account.merge.steps.selectOne.text')"
        />
        <n-step
          :title="accountToMergeInto ? usePersonName(accountToMergeInto) : $t('account.merge.steps.selectTwo.title')"
          :status="stepsStatus.selectTwo"
          :description="$t('account.merge.steps.selectTwo.text')"
        />
        <n-step
          :title="$t('account.merge.steps.confirm.title')"
          :status="stepsStatus.confirm"
          :description="$t('account.merge.steps.confirm.text')"
        />
      </n-steps>
    </TheDataCard>

    <template v-if="currentStep === 'selectTwo'">
      <AccountOverviewTable
        :actions="['select']"
        :hide-sub-accounts="true"
        :where="{ NOT: { OR: [{ id: props.payload.idToMerge }, { role: 'institution' }] } }"
        @select="({ id, personId }) => {
          cloned.idToMergeInto = id
          cloned.personIdToMergeInto = personId
        }"
      />
    </template>

    <template v-else-if="currentStep === 'confirm'">
      <n-alert
        :title="$t('alert.title.notice')"
        :show-icon="false"
        class="w-full"
      >
        <p>
          {{ $t('account.merge.confirm.text', {
            mergeName: usePersonName(accountToMerge),
            mergeIntoName: usePersonName(accountToMergeInto),
          }) }}
        </p>
        <n-divider class="!my-2" />
        <p class="text-base mb-2">
          {{ $t('account.merge.confirm.stats.title') }}
        </p>
        <AccountStats
          :id="cloned.idToMerge"
        />
      </n-alert>

      <div class="flex items-center justify-between">
        <n-button @click="cloned.idToMergeInto = ''">
          <template #icon>
            <ArrowLeftIcon />
          </template>
          {{ $t('button.back') }}
        </n-button>
        <div class="flex items-center gap-2">
          <n-button @click="$emit('close')">
            {{ $t('button.cancel') }}
          </n-button>
          <n-button type="primary" @click="$emit('submit', cloned)">
            {{ $t('button.confirm') }}
          </n-button>
        </div>
      </div>
    </template>
  </div>
</template>
