<script lang="ts" setup>
import type { MergeAccount } from '~/types'

const props = defineProps<{ opener: typeof openAccountMergePopup }>()

const { openAccountMergePopup, openPersonDetailsPage } = useOpeners()
const { data: popupData, close: closePopup } = props.opener

const payload = computed((): MergeAccount | undefined => {
  if (!popupData.value) {
    return undefined
  }
  return {
    idToMerge: popupData.value.id,
    idToMergeInto: popupData.value.idToMergeWith ?? '',
  }
})

const { $trpc, queryClient, useMutation, makeTrpcErrorToast, makeTrpcSuccessToast } = useMutationHelpers()
const { $i18n } = useNuxtApp()
const mergeAccounts = useMutation({
  mutationFn: $trpc.accounts.mergeAccount.mutate,
  onMutate: (payload) => {
    if (payload.personIdToMergeInto) {
      return openPersonDetailsPage({ id: payload.personIdToMergeInto })
    }
    navigateTo('/users/citizens/verified')
  },
  onError: makeTrpcErrorToast({ entity: $i18n.t('account.entity'), mode: 'transfer' }),
  onSuccess: async () => {
    closePopup()

    await queryClient.invalidateQueries({ queryKey: ['accounts', 'getAll'] })
    await queryClient.invalidateQueries({ queryKey: ['accounts', 'getById'] })
    await queryClient.invalidateQueries({ queryKey: ['persons', 'getAll'] })
    await queryClient.invalidateQueries({ queryKey: ['persons', 'getById'] })
    await queryClient.invalidateQueries({ queryKey: ['cases', 'getAll'] })

    makeTrpcSuccessToast({ entity: $i18n.t('account.entity'), mode: 'transfer' })
  },
})
</script>

<template>
  <ThePopup
    v-if="payload"
    :show="Boolean(payload)"
    :title="$t('account.merge.button')"
    width="1400px"
    @close="closePopup"
  >
    <n-spin :show="mergeAccounts.isPending.value">
      <AccountMergeForm
        :payload
        @close="closePopup"
        @submit="mergeAccounts.mutate"
      />
    </n-spin>
  </ThePopup>
</template>
